<template>
  <pie-chart
    title="Répartition CA"
    subTitle="par partenaire"
    :series="series"
    :labels="labels"
    :loading="loading"
    :formatter="formatter"
    :colors="colors"
  />
</template>

<script>
import axios, { cacheTime } from "@axios";

import PieChart from "@/components/common/charts/PieChart.vue";

import { chartColors } from "@/utils/constants";
import { formatCurrency } from "@/utils/formatting";

export default {
  name: "CADistribution",
  components: { PieChart },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      loading: false,
      series: [],
      labels: [],
      formatter: (val) => formatCurrency(val),
      colors: chartColors, //["#ffe700", "#00d4bd", "#826bf8", "#2b9bf4", "#FFA1A1"],
    };
  },
  methods: {
    async getData() {
      if (this.month && this.month.length > 5) {
        const queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnersConfigs && this.partnersConfigs.length > 0
            ? { partnersconfigs: this.partnersConfigs.join(",") }
            : {}),
        };
        this.loading = true;
        const { data: topPartnersData } = await axios.get(
          "/programmatic/best-partners",
          {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }
        );

        this.series = topPartnersData.map((data) => data.ca);
        this.labels = topPartnersData.map((data) => data.name);
        this.loading = false;
      }
    },
  },
  watch: {
    month: function () {
      this.getData();
    },
    sites: function () {
      this.getData();
    },
    partnersConfigs: function () {
      this.getData();
    },
  },
};
</script>
