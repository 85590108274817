<template>
  <v-card>
    <v-card-title>Sites</v-card-title>
    <v-card-text>
      <v-data-table :headers="headers" :items="items" :items-per-page="5">
        <template v-slot:item.revenue="{ item }">
          <span>{{ formatNumber(item.revenue) }}</span>
        </template>
        <template v-slot:item.sessions="{ item }">
          <span>{{ formatNumber(item.sessions) }}</span>
        </template>
        <template v-slot:item.rpm="{ item }">
          <span>{{ formatNumber(item.rpm) }}</span>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import axios, { cacheTime } from "@axios";
import { formatNumber } from "@/utils/formatting";

export default {
  name: "SiteRevenueSessionRpmTable",
  created() {
    this.getData();
  },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Site",
          align: "start",
          value: "site",
        },
        {
          text: "Revenue",
          value: "revenue",
        },
        {
          text: "Sessions",
          value: "sessions",
        },
        {
          text: "RPM",
          value: "rpm",
        },
      ],
      items: [],
    };
  },
  methods: {
    formatNumber,
    async getData() {
      if (this.month && this.month.length > 5) {
        const queryParams = {
          month: this.month,
          limit: 100,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnersConfigs && this.partnersConfigs.length > 0
            ? { partnersconfigs: this.partnersConfigs.join(",") }
            : {}),
        };
        const { data } = await axios.get("/programmatic/top-rpm-per-site", {
          params: queryParams,
          cacheTime: cacheTime,
        });
        this.items = data.map((item) => {
          return {
            site: item.name,
            revenue: item.ca,
            sessions: item.sessions,
            rpm: item.rpm,
          };
        });
      }
    },
  },
  watch: {
    month: function () {
      this.getData();
    },
    sites: function () {
      this.getData();
    },
    partnersConfigs: function () {
      this.getData();
    },
  },
};
</script>
