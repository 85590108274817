<template>
  <v-card>
    <dashboard-chart
      v-if="series[0].data.length"
      title="Top RPM par site"
      type="bar"
      :colors="colors"
      :series="series"
      :categories="categories"
      :chartOptions="dashboardMainChartOptions"
    ></dashboard-chart>
  </v-card>
</template>

<script>
import axios, { cacheTime } from "@axios";
import { colors } from "@/utils/constants";
import { formatNumber } from "@/utils/formatting";
import DashboardChart from "@/views/charts/premium/DashboardChart";

export default {
  name: "TopRpmPerSiteChart",
  components: {
    DashboardChart,
  },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },

    partnersConfigs: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getData();
  },
  data() {
    return {
      mainData: {},
      colors: [colors.rpm],
      series: [
        {
          name: "CA",
          data: [],
        },
      ],
      categories: [],
      dashboardMainChartOptions: {
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        colors: ["#1095f5"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        yaxis: [
          {
            title: {
              text: "Revenue",
            },
            seriesName: "Revenue",
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter: (val) => formatNumber(val),
            },
          },
          {
            seriesName: "Revenue",
            show: false,
            labels: {
              formatter: (val) => formatNumber(val),
            },
          },
          {
            title: {
              text: "Sessions",
            },
            seriesName: "Sessions",
            opposite: true,
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter: (val) => formatNumber(val),
            },
          },
          {
            seriesName: "Sessions",
            show: false,
            labels: {
              formatter: (val) => formatNumber(val),
            },
          },
        ],
        xaxis: {
          categories: this.categories,
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  methods: {
    async getData() {
      if (this.month && this.month.length > 5) {
        const queryParams = {
          month: this.month,
          limit: 10,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnersConfigs && this.partnersConfigs.length > 0
            ? { partnersconfigs: this.partnersConfigs.join(",") }
            : {}),
        };

        const { data } = await axios.get("/programmatic/top-rpm-per-site", {
          params: queryParams,
          cacheTime: cacheTime,
        });

        this.dashboardMainChartOptions = {
          ...this.dashboardMainChartOptions,
          xaxis: {
            categories: this.categories,
          },
        };

        this.series = [
          {
            name: "Top RPM",
            data: data.map((item) => {
              return { x: item.name, y: item.rpm };
            }),
          },
        ];
      }
    },
  },
  watch: {
    month: function () {
      this.getData();
    },
    sites: function () {
      this.getData();
    },
    partnersConfigs: function () {
      this.getData();
    },
  },
};
</script>
