<template>
  <div>
    <v-expansion-panels class="mb-2">
      <v-expansion-panel>
        <v-expansion-panel-header> </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="ml-3">
            <v-col cols="6" sm="4" md="3">
              <partners-groups-filter store="premium"></partners-groups-filter>
            </v-col>
            <v-col cols="6" sm="4" md="6">
              <partners-views-filter
                store="premium"
                :showGroupCASwitch="showGroupCASwitch"
              ></partners-views-filter>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="12">
          <v-row class="d-flex">
            <v-col cols="2">
              <date-filter
                store="premium"
                storeGetter="getMonthDate"
                storeUpdater="updateMonthDate"
                format="month"
                :isRange="false"
              />
            </v-col>
            <v-col cols="4">
              <site-groups-filter store="premium" />
            </v-col>
            <v-col cols="4">
              <sites-filter store="premium" />
            </v-col>

            <v-col cols="2">
              <v-btn
                @click="resetFilters"
                class="pt-7 pb-6 mt-0 my-1"
                outlined
                color="secondary"
              >
                Réinitialiser
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mdiCached } from "@mdi/js";

import DateFilter from "@/components/common/filters/DateFilter";
import SitesFilter from "@/components/common/filters/SitesFilter";
import SiteGroupsFilter from "@/components/common/filters/SiteGroupsFilter";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import PartnersViewsFilter from "@/views/filters/premium/PartnersViewsFilter.vue";
import PartnersGroupsFilter from "@/views/filters/premium/PartnersGroupsFilter.vue";

export default {
  name: "Filters",
  components: {
    PartnersGroupsFilter,
    PartnersViewsFilter,
    ApplyFilters,
    SitesFilter,
    SiteGroupsFilter,
    DateFilter,
    mdiCached,
  },
  props: {
    showGroupCASwitch: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.resetFilters();
  },
  data() {
    return {
      icons: {
        mdiCached,
      },
    };
  },
  methods: {
    resetFilters() {
      let monthDate = new Intl.DateTimeFormat("fr-FR")
        .format(new Date())
        .split("/");
      this.$store.dispatch(
        "premium/updateMonthDate",
        `${monthDate[2]}-${monthDate[1]}`
      );
      this.$store.dispatch("premium/updatePartnerViewsGroups", [9]);
    },
  },
};
</script>

<style>
.v-date-picker-title__date > div {
  font-size: 22px;
}
</style>
