<template>
  <v-row>
    <v-col :cols="showGroupCASwitch ? 8 : 12">
      <v-autocomplete
        outlined
        v-model="selected"
        :items="partnersViewsList"
        :menu-props="{ maxHeight: '400', zIndex: 13 }"
        label="Sélectionner les vues de partenaires"
        multiple
        small-chips
        deletable-chips
        item-text="label"
        item-value="id"
      ></v-autocomplete>
    </v-col>
    <v-col v-show="showGroupCASwitch" cols="4">
      <v-switch inset v-model="groupCA" label="Grouper les CA"></v-switch>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    showGroupCASwitch: {
      type: Boolean,
      default: true,
    },
  },
  async created() {
    this.partnersViewsList = await this.$store.dispatch(
      "premium/getPartnerViewsList"
    );
  },
  data: () => ({
    partnersViewsList: [],
  }),
  computed: {
    groupCA: {
      get() {
        return this.$store.getters["premium/getGroupCA"];
      },
      set(e) {
        this.$store.dispatch("premium/updateGroupCA", e);
      },
    },
    selected: {
      get() {
        return this.$store.getters["premium/getPartnerViews"];
      },
      set(e) {
        this.$store.dispatch("premium/updatePartnerViews", e);
      },
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
