<template>
  <v-card style="position: relative !important">
    <v-card-title> Top Sites par CA </v-card-title>
    <v-card-subtitle> et leurs meilleurs partenaires </v-card-subtitle>
    <v-card-text>
      <div id="container">
        <vue-apex-charts :options="options" :series="series" />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import axios, { cacheTime } from "@axios";
import { formatCurrencyCompact } from "@/utils/formatting";

export default {
  name: "TopSitesTopPartnersChart",
  components: { VueApexCharts },
  props: {
    sites: {
      type: Array,
      default: () => [],
    },
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      series: [],
      options: {
        chart: {
          animation: {
            // enabled: false,
          },
          height: 350,
          id: "apexchart-treemap",
          type: "treemap",
          stacked: true,
          zoom: {
            enabled: false,
          },
          events: {
            click: function (chart, w, e) {
              // change value of the displayed text
              // console.log(chart, w, e);
            },
            mouseMove: function (chart, w, e) {
              // console.log(chart, w, e);
            },
          },
        },
        legend: {
          show: true,
        },
        tooltip: {
          enabled: true,
          custom: undefined,
          fillSeriesColor: false,
          theme: false,
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          x: {
            show: true,
            formatter: undefined,
          },
          y: {
            formatter: undefined,
            title: {
              formatter: (seriesName) => seriesName,
            },
          },

          marker: {
            show: true,
          },
          fixed: {
            enabled: true,
            position: "topRight",
            offsetX: 0,
            offsetY: 0,
          },
        },
        // tooltip: {
        //   custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //     return (
        //       '<div class="arrow_box">' +
        //       "<span>" +
        //       w.globals.labels[dataPointIndex] +
        //       ": " +
        //       series[seriesIndex][dataPointIndex] +
        //       "</span>" +
        //       "</div>"
        //     );
        //   },
        // },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  methods: {
    async getData() {
      if (this.month && this.month.length > 5) {
        const queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnersConfigs && this.partnersConfigs.length > 0
            ? { partnersconfigs: this.partnersConfigs.join(",") }
            : {}),
        };

        const { data } = await axios.get(
          "/programmatic/top-sites-ca-partners",
          {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }
        );
        this.series = [];

        for (const key of data) {
          const partners = [];
          for (const datum of key.data) {
            // format number
            partners.push({
              x: datum.partner + " (" + formatCurrencyCompact(datum.ca) + ")",
              y: datum.ca,
            });
          }
          this.series.push({
            name: key.name,
            data: partners,
          });
        }
        this.chartOptions = {
          ...this.chartOptions,
          series: this.series,
        };
      }
    },
  },
  watch: {
    month: function () {
      this.getData();
    },
    sites: function () {
      this.getData();
    },
    partnersConfigs: function () {
      this.getData();
    },
  },
};
</script>

<style>
/*.apexcharts-canvas {*/
/*  position: relative;*/
/*}*/

/*.apexchartsqh2itgok {*/
/*  position: relative;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  user-select: none;*/
/*  -webkit-tap-highlight-color: transparent;*/
/*}*/

/*.apexcharts-tooltip,*/
/*.apexcharts-xaxistooltip {*/
/*  background-color: #fff !important;*/
/*  position: fixed;*/
/*  top: 0;*/
/*  left: 0;*/
/*  color: #ec0c32;*/
/*  z-index: 2222 !important;*/
/*}*/
/*.apexcharts-tooltip.apexcharts-active,*/
/*.apexcharts-xaxistooltip.apexcharts-active {*/
/*  display: block;*/
/*}*/

#container {
  position: relative;
  width: 100%;
  height: 100%;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
#apexchartsapexchartxtreemap {
  height: 50%;
  width: 50%;
  display: block;
  position: relative;
}
/*.apexcharts-tooltip {*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: 0;*/
/*  color: #ec0c32;*/
/*  z-index: 2222 !important;*/
/*  background: rebeccapurple;*/
/*}*/
</style>
