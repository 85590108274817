<template>
  <div :class="!isInTemplate ? 'pa-6 ma-4' : ''">
    <div v-if="isInTemplate">
      <p class="text-2xl mb-6">Infos générales</p>
      <filters></filters>
    </div>
    <v-row>
      <v-col cols="12">
        <top-stats-card
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></top-stats-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ca-sessions-objectives-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></ca-sessions-objectives-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
        ><top-rpm-per-site-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></top-rpm-per-site-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6"
        ><rpm-objectives-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></rpm-objectives-chart>
      </v-col>
      <v-col cols="6"
        ><c-a-distribution
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></c-a-distribution>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <top-sites-top-partners-chart
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></top-sites-top-partners-chart>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <site-revenue-session-rpm-table
          :month="monthDate"
          :partners-configs="partnersConfigs"
          :sites="sites"
        ></site-revenue-session-rpm-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CaSessionsObjectivesChart from "@/views/cards/general/CaSessionsObjectivesChart";
import RpmObjectivesChart from "@/views/cards/general/RPMObjectivesChart";
import Filters from "@/views/filters/general/Filters";
import TopRpmPerSiteChart from "@/views/cards/general/TopRpmPerSiteChart";
import CADistribution from "@/views/cards/premium/CADistributionChart";
import SiteRevenueSessionRpmTable from "@/views/cards/general/SiteRevenueSessionRpmTable";
import TopSitesTopPartnersChart from "@/views/cards/general/TopSitesTopPartnersChart";
import TopStatsCard from "@/views/cards/general/TopStatsCard";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "GeneralDecoupled",
  components: {
    TopSitesTopPartnersChart,
    SiteRevenueSessionRpmTable,
    CADistribution,
    Filters,
    CaSessionsObjectivesChart,
    RpmObjectivesChart,
    TopRpmPerSiteChart,
    TopStatsCard,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  async created() {
    let monthDate = new Intl.DateTimeFormat("fr-FR")
      .format(new Date())
      .split("/");
    this.$store.dispatch(
      "premium/updateMonthDate",
      `${monthDate[2]}-${monthDate[1]}`
    );
    const month = this.$route.query.month;
    let siteGroups = this.$route.query.siteGroups;
    if (month) {
      await this.$store.dispatch("premium/updateMonthDate", month);
    }
    if (siteGroups) {
      siteGroups = siteGroups.split(",");
      await this.$store.dispatch("premium/updateSiteGroups", siteGroups);
    }
  },
  computed: {
    isInTemplate() {
      return this.$route.name === "general-dashboard";
    },
    sites() {
      return this.$store.getters["premium/getSites"];
    },
    monthDate() {
      return this.$store.getters["premium/getMonthDate"];
    },
    partnersConfigs() {
      return this.$store.getters["premium/getPartnerViews"];
    },
  },
};
</script>
