<template>
  <v-autocomplete
    outlined
    v-model="selected"
    :items="partnersGroupsList"
    :menu-props="{ maxHeight: '400', zIndex: 13 }"
    label="Sélectionner les groupes de vues de partenaires"
    multiple
    small-chips
    deletable-chips
    item-text="name"
    item-value="id"
  ></v-autocomplete>
</template>

<script>
export default {
  async created() {
    this.partnersGroupsList = await this.$store.dispatch(
      "premium/getPartnerViewsGroupsList"
    );
  },
  data: () => ({
    partnersGroupsList: [],
  }),
  computed: {
    selected: {
      get() {
        return this.$store.getters["premium/getPartnerViewsGroups"];
      },
      set(e) {
        this.$store.dispatch("premium/updatePartnerViewsGroups", e);
      },
    },
  },
};
</script>

<style scoped>
[role="menu"] {
  top: 233px !important;
  right: 0 !important;
}
</style>
